import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w09KG05Weben00 = () => (
  <Layout>
    <SEO title="w09KG05Weben00" />

    <h1 id="ueberschrift">Kreatives Gestalten</h1>
    <h2>Weben für Anfänger &emsp;  ©&nbsp;1996</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w09KG05Weben00&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Einen Rahmen und ein Schiffchen;<br />
        Wolle und Fäden;<br />
        ein wenig Geduld und Übung.
      </p>
      <p>
        Gemeinsam mit anderen können Sie Ihr kreatives Talent suchen und entdecken, indem Sie 
        unter Anleitung Ihren ersten Topflappen oder Untersetzer weben.
        Genießen Sie die Zeit beim geselligen Experimentieren, Fluchen, Beneiden und Freuen.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz pro Teilnehmer + 1 Sitzplatz extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w09KG05Weben00
